import React, { useEffect, useState, Fragment } from "react";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Dialog, Transition, Menu, Popover } from '@headlessui/react'
import Toggle from 'react-toggle'
import { CheckIcon } from '@heroicons/react/outline'
import { InView } from 'react-intersection-observer';
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { CSVLink } from "react-csv";
import ReactTooltip from 'react-tooltip';

import Drawer from "../common/drawer";
import ErrorBar from "../common/errorBar";
import PropsRoute from '../common/propsroute';
import ErrorBoundary from "../common/errorboundary";
import NavBar from "../common/navbar";
import SelectPopover from "../common/selectPopover";
import FeaturePaywallWrapper from "../../components/account/featurePaywallWrapper"

import TrackedKeywordNew from "./trackedkeywordnew";
import TrackedKeywordAll from "./trackedkeywordall";
import TrackedKeywordAnalyze from "./trackedkeywordanalyze";

import Aggregates from "../../components/reddit/aggregates";

import { redditService } from '../../services/reddit';
import { savedSearchOperations } from "../../redux/saved"
import { savedSearchesService } from '../../services/savedSearches';
import { digestsService } from '../../services/digests';

import { hasPro, hasStarter } from '../../utils/account';
import { trackEvent } from '../../utils/tracking';
import { getConversationCsvDict, applyFrontendFilters } from '../../utils/reddit';
import { slugify } from '../../utils/text';
import { contains } from '../../utils/numbers';
import { toQueryString } from '../../utils/urls';


// right sidebar for a saved search result
// baseUrl is the url that this component stems from (it's used in multiple places)
const TrackedKeywordDrawer = ({
  match,
  history,
  location,
  closeModal,
  currentUser,
  refetchAll,
  clearSavedSearchUnread,
  urlRoot,  // set if using this component in audience
}) => {
  const { savedSearchHash } = match.params; 
  const [savedSearch, setSavedSearch] = useState(null);
  const [loading, setLoading] = useState(false); // for the saved search
  const [error, setError] = useState(null);
  const [downloadableResults, setDownloadableResults] = useState([]);  // set from child component
  
  const [notificationChannels, setNotificationChannels] = useState([]);
  const [loadingNotificationChannels, setLoadingNotificationChannels] = useState(false);
  const [loadedNotificationChannels, setLoadedNotificationChannels] = useState(false);

  // form filters  
  const [currentName, setCurrentName] = useState('');
  const [currentLabel, setCurrentLabel] = useState('');
  const [currentNotify, setCurrentNotify] = useState(false);
  const [currentNotifyInstantly, setCurrentNotifyInstantly] = useState(false);
  const [currentNotificationChannel, setCurrentNotificationChannel] = useState('');
  const [saving, setSaving] = useState(false);
  const [matchCriteriaInclude, setMatchCriteriaInclude] = useState('');
  const [matchCriteriaExclude, setMatchCriteriaExclude] = useState('');

  const isEffectiveProSubscriber = hasPro(currentUser);
  const isEffectiveStarterSubscriber = hasStarter(currentUser);

  // for viewing ALL & NEW results, as well as their aggregates
  const [results, setResults] = useState([]);
  const [fetching, setFetching] = useState(false); // for actual convos
  const [aggregates, setAggregates] = useState(null);
  const [fetchingExtra, setFetchingExtra] = useState(false);
  const [fetchingAggregates, setFetchingAggregates] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [frontendFilters, setFrontendFilters] = useState({});  // {key: [value1, value2]}

  useEffect(() => {
    fetchSavedSearch()
  }, [
    savedSearchHash,
  ]); // Run when saved search ID changes

  useEffect(() => {
    if (!savedSearch || results.length) return;
    fetchAllResults()
  }, [
    savedSearch,
  ]); // Get results when we get the saved search

  useEffect(() => {
    prepareDownloadCSV()
  }, [
    results,
  ]); // Compile CSV when we got results



  // actions
  const fetchSavedSearch = () => {
    setLoading(true)
    setError(null)
    savedSearchesService.getSavedSearch(match.params.savedSearchHash, (results) => {
      if (results && results.data){
        setSavedSearch(results.data)
        setCurrentName(results.data.name)
        setCurrentLabel(results.data.label)
        setCurrentNotify(results.data.notify)
        setCurrentNotifyInstantly(results.data.notify_instantly)
        setCurrentNotificationChannel(results.data.notification_channel_id || "")
        setMatchCriteriaInclude(results.data.match_criteria?.include || "")
        setMatchCriteriaExclude(results.data.match_criteria?.exclude || "")
        // fetchAllResults();
        setLoading(false);
      }
    }, (response, error) => {
      setError(error)
      setLoading(false);
    })
  }

  const deleteSearch = () => {
    savedSearchesService.deleteSavedSearch(match.params.savedSearchHash, (results) => {
      // refetch
      if (refetchAll){
        refetchAll();
      }
    }, (response, error) => {
      setError(error)
      alert("Failed to delete")
    });

    closeModal();
  }

  const updateSearch = (params) => {
    setSaving(true)
    savedSearchesService.updateSavedSearch(match.params.savedSearchHash, params, (results) => {
      setSavedSearch(results.data)
      setSaving(false)

      // refetch (should we? if we refetch the "new" ones go away...)
      // if (refetchAll){
      //   refetchAll();
      // }
    }, (response, error) => {
      setError(error)
      setSaving(false)
      // alert("Failed to delete")
    });
  }

  const fetchNotificationChannels = () => {
    setLoadingNotificationChannels(true);
    digestsService.getNotificationChannels((response) => {
      setNotificationChannels(response.data);
      setLoadingNotificationChannels(false);
      setLoadedNotificationChannels(true);
    }, (error) => {
      setLoadingNotificationChannels(false);
      setLoadedNotificationChannels(true);
    });
  }

  const getResultParams = () => {
    // params are just the saved ones
    var params = Object.assign({}, savedSearch.params);
    if (savedSearch.search_type === "RS"){
      params['type'] = 'submissions';
    } else if (savedSearch.search_type === "RC"){
      params['type'] = 'comments';
    } else if (savedSearch.search_type === "RA"){
      // not available yet
      params['type'] = 'reddit-all';
    }
    return params
  }

  const fetchAllResults = () => {
    if (!isEffectiveStarterSubscriber){
      // don't fetch results if we don't have pro or on free trial
      return;
    }

    // fetch results based on the suggestion
    const params = getResultParams()

    // track the search
    const trackParams = Object.assign({
      "platform": "Reddit",
      "keyword": params.keyword,
      "saved_search": "true",
      "count_subreddits": params.subreddits ? params.subreddits.split(",").length : 0,
    }, params)
    trackEvent("PerformConversationSearch", trackParams);

    // fetchMethod is either fetching submissions or comments, defined above
    // delete params['type']; // don't need this guy in the actual params
    setFetching(true);
    setError(false);
    redditService.getConversations(params,
      (response) => {
        setResults(response.data.results);
        fetchExtras(response.data.results);
        // fetchAggregates(); // not needed here at this time
        setFetching(false);
        clearSavedSearchUnread(savedSearch.hash); // clear unread from state
      }, (error, response) => {
        setFetching(false);
        if (error.response && error.response.status === 500){
          setError("Error fetching results from Reddit.")
        } else {
          setError(response || "Error fetching results from Reddit.")
        }
      });
  }

  // gets extra data on this query, updates results
  const fetchExtras = (currentResults) => {
    const allFilters = getResultParams();
    setFetchingExtra(true);
    redditService.getConversationExtras(allFilters,
      (response) => {
        // update responses based on the IDs
        const updates = response.data;
        var newResults = currentResults.map((r) => {
          if (updates[r.id]){
            r = Object.assign(r, updates[r.id])
          }
          return r
        });
        setResults(newResults);
        setFetchingExtra(false);
      }, (error, response) => {
        setFetchingExtra(false);
        console.error("error fetching extras", response)
      });
  }

  // gets aggregate data on this query
  const fetchAggregates = () => {
    const allFilters = getResultParams();
    setFetchingAggregates(true);
    redditService.getConversationAggregates(allFilters,
      (response) => {
        setAggregates(response.data);
        setFetchingAggregates(false);
      }, (error, response) => {
        setFetchingAggregates(false);
        console.error("error fetching aggregates", response)
      });
  }

  const clearFrontendFilters = () => {
    setFrontendFilters({})
  }

  // apply a frontend filter (or several) to limit display of existing results
  const applyFrontendFilter = (key, value) => {
    // if value is already applied in that filter, remove it. otherwise create/add
    var currentValue = frontendFilters[key] || [];
    if (contains(currentValue, value)){
      currentValue = currentValue.filter(v => JSON.stringify(v) != JSON.stringify(value))
    } else {
      currentValue.push(value)
    }

    const newFilters = Object.assign(frontendFilters, {[key]: currentValue});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const clearFrontendFilter = (key) => {
    const newFilters = Object.assign(frontendFilters, {[key]: undefined});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const prepareDownloadCSV = () => {
    // gets the comments + submissions, and puts them into a format to be downloaded. passes to parent
    setDownloadableResults(getConversationCsvDict(results || []))
  }


  // display utils 

  const notificationChannelTypes = { // TODO: put in constnats file and share with notifications.jsx
    'EM': 'Email',
    'SE': 'Slack',
    'DI': 'Discord',
  }

  // filter results based on frontend filters
  // var filteredResults = applyFrontendFilters(results || [], frontendFilters);
  var filteredResults = results.map(r => {
    // if it's an AI match, add the match criteria information
    if (savedSearch && savedSearch.match_criteria && savedSearch.ai_matches){
      const match = savedSearch.ai_matches.find(m => m.id === r.id);
      if (match){
        r.ai_match = match
      }
    }
    return r
  });

  // get "new" ones, based on last view
  const oneWeekAgo = (new Date()).setDate((new Date()).getDate() - 7);
  const lastViewTimestamp = (savedSearch && savedSearch.last_viewed_at) || oneWeekAgo;
  var newResults = filteredResults.filter(r => r.timestamp_utc > lastViewTimestamp); // TODO: use the below


  // get display tabs
  const countResults = results && results.length;
  const countFilteredResults = filteredResults.length;
  const baseUrl = `${urlRoot || '/conversations/tracked/'}${savedSearchHash}/`;
  var tabs = [
    { name: `${!fetching && newResults.length ? newResults.length : ''} New`, link: `${baseUrl}new/` },
    { name: `All Results`, link: `${baseUrl}all/`, exact: true },
    // { name: 'Filters', link: `${baseUrl}aggregate/`},
    {name: 'Patterns', link: `${baseUrl}patterns/`},
  ]

  // highlighted keyword
  var keywordToHighlight = savedSearch && savedSearch.params.keyword || '';
  if (frontendFilters.categoryKeywords && frontendFilters.categoryKeywords.length){
    keywordToHighlight = `${frontendFilters.categoryKeywords.join(",")},${keywordToHighlight}`
  }
  if (frontendFilters.phrase && frontendFilters.phrase.length){
    keywordToHighlight = `${frontendFilters.phrase.join(",")},${keywordToHighlight}`
  }

  return (
    <Drawer close={closeModal}>
      <ErrorBoundary>
        <div className="flex flex-col h-full">
          <Helmet><title>Conversations | Tracked</title></Helmet>

          <div className="p-4">
            <div className="flex items-start justify-between">
              {savedSearch ? (
                <div className="text-lg font-medium flex items-center">
                  {savedSearch.notify ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 opacity-50 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                  ) : (
                    <svg className="h-6 w-6 mr-2 opacity-50 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.143 17.082a24.248 24.248 0 003.844.148m-3.844-.148a23.856 23.856 0 01-5.455-1.31 8.964 8.964 0 002.3-5.542m3.155 6.852a3 3 0 005.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 003.536-1.003A8.967 8.967 0 0118 9.75V9A6 6 0 006.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53" />
                    </svg>
                  )}

                  <div>{savedSearch && savedSearch.name}</div>

                  <ReactTooltip id={`search-detail-icon-${savedSearch.hash}`} effect="solid" place="right" backgroundColor="#fafafa" textColor="black" className="w-72"/>
            
                  {savedSearch.label ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 h-6 opacity-50 ml-2 -bottom-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}
                      data-for={`search-detail-icon-${savedSearch.hash}`} data-tip={savedSearch.label}
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                    </svg>
                  ) : ''}

                  {savedSearch.notify && savedSearch.notify_instantly ? (
                    <svg className="h-6 h-6 opacity-50 ml-2 -bottom-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                      data-for={`search-detail-icon-${savedSearch.hash}`} data-tip="Instant Alerts"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                    </svg>
                  ) : ''}

                  {savedSearch.match_criteria && (savedSearch.match_criteria.include || savedSearch.match_criteria.exclude) ? (
                    <svg className="h-6 h-6 opacity-50 ml-2 -bottom-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                      data-for={`search-detail-icon-${savedSearch.hash}`} data-html={true}
                      data-tip={`Include: ${savedSearch.match_criteria.include || 'N/A'}<br/><br/>Exclude: ${savedSearch.match_criteria.exclude || 'N/A'}`}
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                    </svg>
                  ) : ''} 

                  <Popover className="relative text-base -bottom-1">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`flex-shrink-0 inline-flex items-center justify-center ml-2 focus:outline-none focus:ring-transparent focus:border-none rounded-lg text-gray-500 hover:text-gray-300`}
                        >
                          <span className="sr-only">Settings</span>
                          <svg id="configure-popover-button" className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>

                        </Popover.Button>

                        <Transition
                          show={open}
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className={`absolute left-10 -top-2 z-50 w-72 bg-white text-black p-4 rounded-md overflow-hidden shadow-lg bg-white flex flex-col space-y-4`}
                          >
                            <div>
                              <ReactTooltip id={`saved-search-info`} effect="solid" place="right" backgroundColor="#000" textColor="white" className="w-72"/>
            
                              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                                <div>Name</div>
                              </div>
                              
                              <div className="flex items-center rounded-md">
                                <input type="text"
                                  className="bg-gray-200 h-8 p-1 px-2 text-sm rounded-r-md border-none w-full focus:ring-0"
                                  placeholder={"Name this search"} autoComplete="off" spellCheck="off"
                                  autoFocus={true}
                                  disabled={saving}
                                  value={currentName}
                                  onChange={(e) => {setCurrentName(e.target.value)}}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                                <div>Notify</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-4 h-4 ml-2"
                                  data-for={`saved-search-info`} data-tip="Include this particular tracked search in your daily digest notifications"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                              </div>
                              
                              <div className="flex items-center rounded-md">
                                <Toggle
                                  defaultChecked={currentNotify}
                                  className="toggle-darkmode"
                                  icons={{
                                    checked: <CheckIcon className="h-5 w-5"/>,
                                    unchecked: null
                                  }}
                                  onChange={(e) => {
                                    setCurrentNotify(e.target.checked ? true : false)
                                  }}/>
                              </div>
                            </div>

                            <div>
                              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                                <div>Instant</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-4 h-4 ml-2"
                                  data-for={`saved-search-info`} data-tip={`Notify new keyword matches right away, as opposed to your daily digest (best for brand monitoring, and not high-volume keywords). ${isEffectiveStarterSubscriber ? '' : 'Requires GummySearch Starter.'}`}
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                              </div>
                              
                              <div className="flex items-center rounded-md">
                                <Toggle
                                  defaultChecked={currentNotifyInstantly}
                                  className="toggle-darkmode"
                                  disabled={!isEffectiveStarterSubscriber}
                                  icons={{
                                    checked: <CheckIcon className="h-5 w-5"/>,
                                    unchecked: null
                                  }}
                                  onChange={(e) => {
                                    setCurrentNotifyInstantly(e.target.checked ? true : false)
                                    if (e.target.checked){
                                      setMatchCriteriaExclude('')
                                      setMatchCriteriaInclude('')
                                    }
                                  }}/>
                              </div>
                            </div>

                            <div>
                              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                                <div>Notification Channel</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-4 h-4 ml-2"
                                  data-for={`saved-search-info`} data-tip={`Send these keyword matches to a specific notification channels. Update notification channels in your account settings.`}
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                              </div>
                              
                              <div className="flex items-center rounded-md w-full">
                                <InView as="div" className="w-full" onChange={(inView, entry) => {
                                  if (inView && !loadingNotificationChannels && !loadedNotificationChannels){
                                    fetchNotificationChannels()
                                  }
                                }}>
                                  {loadingNotificationChannels ? (
                                    <div className="h-8">Loading...</div>
                                  ) : notificationChannels.length ? (
                                    <select
                                      className="bg-gray-200 h-8 p-1 px-2 text-sm rounded-r-md border-none w-full focus:ring-0"
                                      disabled={saving}
                                      id="notification-channel-selector"
                                      value={currentNotificationChannel ? String(currentNotificationChannel) : ""}
                                      onChange={(e) => {
                                        setCurrentNotificationChannel(e.target.value)
                                      }}
                                    >
                                      <option value="">Default notification channel</option>
                                      {notificationChannels.map(c => (
                                        <option key={c.id} value={c.id}>{c.nickname || notificationChannelTypes[c.channel_type] || 'Unknown channel'}</option>
                                      ))}
                                    </select>
                                  ) : (
                                    <div className="h-8">Default (account email)</div>
                                  )}
                                  
                                </InView>
                              </div>
                            </div>

                            <div>
                              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                                <div>Label</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-4 h-4 ml-2"
                                  data-for={`saved-search-info`} data-tip="Add an optional label to group multiple tracked searches together"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                              </div>
                              
                              <div className="flex items-center rounded-md">
                                <input type="text"
                                  className="bg-gray-200 h-8 p-1 px-2 text-sm rounded-r-md border-none w-full focus:ring-0"
                                  placeholder={"Label this search (optional)"} autoComplete="off" spellCheck="off"
                                  value={currentLabel}
                                  onChange={(e) => {setCurrentLabel(e.target.value)}}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                                <div>Match Criteria</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-4 h-4 ml-2"
                                  data-for={`saved-search-info`} data-tip="Add an optional description of the kinds of results you'd like to see included, we'll use AI to score/filter results"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                              </div>
                              
                              <div className="flex items-center rounded-md">
                                <textarea type="text"
                                  className="bg-gray-200 h-16 p-1 px-2 text-sm rounded-r-md border-none w-full focus:ring-0"
                                  placeholder={`AI based match criteria ${currentUser.features.tracked_keyword_ai_criteria ? (currentNotifyInstantly ? '(not available for instant matches)' : '(optional)') : '(Pro only)'}`}
                                  autoComplete="off" spellCheck="off"
                                  value={matchCriteriaInclude}
                                  disabled={!currentUser.features.tracked_keyword_ai_criteria || currentNotifyInstantly}
                                  onChange={(e) => {setMatchCriteriaInclude(e.target.value)}}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                                <div>Exclude Criteria</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-4 h-4 ml-2"
                                  data-for={`saved-search-info`} data-tip="Add an optional description of the kinds of results you DO NOT want to see, we'll use AI to filter them out"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                              </div>
                              
                              <div className="flex items-center rounded-md">
                                <textarea type="text"
                                  className="bg-gray-200 h-16 p-1 px-2 text-sm rounded-r-md border-none w-full focus:ring-0"
                                  placeholder={`AI based exclude criteria ${currentUser.features.tracked_keyword_ai_criteria ? (currentNotifyInstantly ? '(not available for instant matches)' : '(optional)') : '(Pro only)'}`}
                                  autoComplete="off" spellCheck="off"
                                  value={matchCriteriaExclude}
                                  disabled={!currentUser.features.tracked_keyword_ai_criteria || currentNotifyInstantly}
                                  onChange={(e) => {setMatchCriteriaExclude(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div>
                              <button
                                className="text-center items-center shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 w-full p-2 rounded-sm"
                                onClick={() => {
                                  if (!currentName) return;
                                  updateSearch({
                                    'name': currentName,
                                    'label': currentLabel,
                                    'notify': currentNotify,
                                    'notify_instantly': currentNotifyInstantly,
                                    'notification_channel_id': currentNotificationChannel ? parseInt(currentNotificationChannel) : null,
                                    'match_criteria': {'include': matchCriteriaInclude, 'exclude': matchCriteriaExclude},
                                  })
                                  close()
                                }} disabled={saving || !currentName}
                              >Save</button>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                    {/*<div className="ml-4 text-xs">
                      {savedSearch && editingLabel ? (
                        <div className="rounded-sm px-2 py-1 bg-gray-800 flex items-center opacity-75 hover:opacity-100 cursor-pointer">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                          </svg>
                          <form className="flex items-center p-0" onSubmit={(e) => {
                            e.preventDefault();
                            updateSearch({'label': labelText});
                            setEditingLabel(false);
                          }}>
                            <input
                              type="text"
                              name="label"
                              autoComplete="off" autoFocus="off"
                              className={`p-0 bg-gray-800 border-gray-700 placeholder-gray-400 text-xs text-white block text-black w-full border-none focus:ring-transparent focus:border-cyan-500`}
                              placeholder={`Name label`}
                              value={labelText} onChange={(e) => {
                                setLabelText(e.target.value);
                              }}
                            />
                          </form>
                        </div>
                      ) : savedSearch && savedSearch.label ? (
                        <div className="rounded-sm px-2 py-1 bg-gray-800 font-bold flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                          </svg>
                          <div className="hover:underline cursor-pointer" onClick={() => {
                            setLabelText(savedSearch.label);
                            setEditingLabel(true);
                          }}>
                            {savedSearch.label}
                          </div>
                          <div className="ml-1 cursor-pointer opacity-75 hover:opacity-100 cursor-pointer" onClick={() => {
                            setLabelText('');
                            updateSearch({'label': ''});
                            setEditingLabel(false);
                          }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      ) : savedSearch && !savedSearch.label ? (
                        <div className="rounded-sm px-2 py-1 bg-gray-800 flex items-center opacity-75 hover:opacity-100 cursor-pointer"
                          onClick={() => setEditingLabel(true)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                          </svg>
                          Add Label
                        </div>
                      ) : ''}
                    </div>*/}
                </div>
              ) : <div className="flex-1" />}
              <div className="ml-3 h-7 flex items-center space-x-2">
                

                <Popover className="relative">
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={`rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center ${open ? 'text-white' : ''}`}
                      >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                        <span className="hidden sm:inline-block">More</span>
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 right-12 top-0 px-2 w-screen max-w-xs sm:px-0"
                        >
                          <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white text-gray-900 p-4">

                              <div onClick={closeModal}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <div className="ml-4">
                                  <p className="font-medium text-gray-700">Close</p>
                                </div>
                              </div>

                              <Link to={`/reddit/${savedSearch && toQueryString(getResultParams())}`}
                                disabled={!savedSearch}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                                </svg>
                                <div className="ml-4 flex items-center">
                                  <p className="font-medium text-gray-700">Advanced Search</p>
                                  {!isEffectiveStarterSubscriber ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-5 h-5 text-yellow-400">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                  ) : ''}
                                </div>
                              </Link>

                              {currentUser.features.download_csv ? (
                                <CSVLink
                                  data={downloadableResults}
                                  filename={savedSearch ? `${slugify(savedSearch.name)}.csv` : "gummysearch-download.csv"}
                                  // className="btn btn-primary"
                                  target="_blank"
                                  onClick={close}
                                  disabled={!downloadableResults || !downloadableResults.length || !currentUser.features.download_csv}
                                  className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                  </svg>
                                  <div className="ml-4 flex items-center">
                                    <p className="font-medium text-gray-700">Download</p>
                                  </div>
                                </CSVLink>
                              ) : ''}
  
                              <div onClick={() => {
                                if (window.confirm("Are you sure you want to delete this tracked keyword?")){
                                  deleteSearch()
                                }
                              }}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                                <div className="ml-4">
                                  <p className="font-medium text-gray-700">Delete</p>
                                </div>
                              </div>
                              
                            </div>
                            
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <button
                  className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center space-x-1"
                  onClick={closeModal}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  <span className="hidden sm:inline-block">Close</span>
                </button>

              </div>
            </div>
          </div>

          <FeaturePaywallWrapper featureKey={"keyword-tracking"} className={'mt-10 mx-auto overflow-auto'} 
            currentUser={currentUser}
          >
            <div className="px-4 flex items-center flex-wrap border-b border-solid border-gray-800">
              <NavBar tabs={tabs} />

              <div className="ml-auto">
                {savedSearch && !savedSearch.match_criteria.include && !savedSearch.match_criteria.exclude ? (
                  <button onClick={() => {
                    // click on configure-popover-button button
                    const button = document.getElementById('configure-popover-button');
                    if (button && button.parentElement) {
                      button.parentElement.click();
                    }
                  }}
                    className="rounded-md text-sm text-green-400 hover:text-green-300 focus:outline-none flex items-center space-x-1"
                  >
                    New: AI-based matching
                  </button>
                ) : ''}

              </div>
            </div>
            
            {savedSearch ? (
              <div id="search-results-content" className="flex-grow overflow-auto flex flex-col">
                <Switch>
                  <Redirect exact path={`${baseUrl}`} to={`${baseUrl}all/`} />
                  <PropsRoute path={`${baseUrl}new/`} component={TrackedKeywordNew}
                    savedSearch={savedSearch} currentUser={currentUser} baseUrl={baseUrl} keyword={savedSearch.params.keyword}
                    results={newResults} fetching={loading || fetching} />
                  <PropsRoute path={`${baseUrl}all/`} component={TrackedKeywordAll}
                    savedSearch={savedSearch} currentUser={currentUser} baseUrl={baseUrl} keyword={keywordToHighlight}
                    results={filteredResults} fetching={loading || fetching} />
                  {/* <Route path={`${baseUrl}aggregate/`}
                    render={(props) => {
                      return (
                        <div id="aggregates-pane" className="overflow-y-auto px-6 py-4" disabled={fetching}>
                          <Helmet><title>Conversations | Tracked | Aggregate</title></Helmet>
                          <Aggregates
                            results={results}
                            aggregates={aggregates}
                            filteredResults={filteredResults}
                            frontendFilters={frontendFilters}
                            applyFrontendFilter={applyFrontendFilter}
                            clearFrontendFilter={clearFrontendFilter}
                            fetching={fetching}
                            fetchingExtra={fetchingExtra}
                            fetchingAggregates={fetchingAggregates}
                            hideTitle={true}
                            // hideSections={['hot', 'top'].includes(suggestion.slug) ? ['velocity_frequency'] : []}
                            // keyword={params.keyword}
                            // velocity={results && results.velocity}
                            // allKeywords={results && results.keywords}
                          />
                        </div>
                      );
                    }}
                  /> */}
                  <PropsRoute path={`${baseUrl}patterns/`} component={TrackedKeywordAnalyze}
                    savedSearch={savedSearch} currentUser={currentUser} baseUrl={baseUrl} keyword={keywordToHighlight}
                    results={filteredResults} fetching={loading || fetching}

                    filterParams={getResultParams()}
                  />
                </Switch>
              </div>
            ) : ''}
          </FeaturePaywallWrapper>
          
          {error ? (
            <ErrorBar error={error} setError={setError} onRetry={fetchAllResults} />
          ) : ''}    
        </div>
      </ErrorBoundary>  
    </Drawer>
  );
}

// this is shared state, so wrap in a dispatch
const mapDispatchToProps = (dispatch) => {
  // const clearSavedSearchUnread = (hash) => {
  //   dispatch(savedSearchOperations.clearSavedSearchUnread(hash))
  // };
  return {
    // clearSavedSearchUnread,
  };
};

export default connect(null, mapDispatchToProps)(TrackedKeywordDrawer);

