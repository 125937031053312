import React from "react"
import {
  Link
} from "react-router-dom";


const ErrorBar = ({ error, setError, onRetry }) => {
  var errorMessage = error;
  if (errorMessage.constructor == Object) {
    errorMessage = errorMessage[Object.keys(errorMessage)[0]]
  }
  return (
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-10">
      <div className="max-w-4xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-red-600 shadow-lg">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-red-800">
                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                </svg>
              </span>
              <p className="ml-3 font-medium text-white">
                <span>{errorMessage}. </span>
                {errorMessage === "You've hit your free tier limit" ? (
                  <div className="space-x-2 inline">
                    <Link to="/limit-reached/" className="underline cursor-pointer">Browse plans</Link>
                    {/* <Link to="/account/subscription/select/" className="underline cursor-pointer">Browse plans</Link> */}
                    <Link to="/account/subscription/day-pass/" className="underline cursor-pointer">Buy day pass</Link>
                    {/*<Link to="/account/subscription/" className="underline cursor-pointer">Upgrade to continue</Link>*/}
                  </div>
                ) : errorMessage === "You've used all of your AI credits for the month" ? (
                  <div className="space-x-2 inline">
                    {/*<Link to="/limit-reached/" className="underline cursor-pointer">What's next?</Link>*/}
                    <Link to="/account/subscription/select/" className="underline cursor-pointer">Upgrade plan</Link> or 
                    <Link to="/account/subscription/limits/AI/" className="underline cursor-pointer">Increase limits</Link>
                  </div>
                ) : onRetry ? (
                  <span className="underline cursor-pointer" onClick={() => onRetry()}>Retry</span>
                ) : ''}
              </p>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button type="button" className="-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setError(null)}
              >
                <span className="sr-only">Dismiss</span>
                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorBar;
