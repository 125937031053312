import React, { useState, useEffect } from "react"
import {
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import { getParamsFromUrl, toQueryString } from '../utils/urls';

import Layout from "../components/layout";
import ErrorBoundary from "../components/common/errorboundary";
import DrawerRoute from "../components/common/drawerroute";
import NavBar from "../components/common/navbar";
import ErrorBar from "../components/common/errorBar";

import Header from "../components/reddit/header";
import Aggregates from "../components/reddit/aggregates";
import Results from "../components/reddit/results";
import EmptySearch from "../components/reddit/emptysearch";
import SubmissionContents from "../components/reddit/submissioncontents";
import SubmissionDrawer from "../components/reddit/submissiondrawer";
import CommentContents from "../components/reddit/commentcontents";
import CommentDrawer from "../components/reddit/commentdrawer";
import ResultsAnalyzePatterns from "../components/language/patterns";
import ResultsAnalyzeQuestion from "../components/language/questionAnswer";
import FeaturePaywallWrapper from "../components/account/featurePaywallWrapper"

import { redditService } from '../services/reddit';
import { defaultParams } from '../components/reddit/constants'
import { trackEvent } from '../utils/tracking';
import { contains } from '../utils/numbers';
import { applyFrontendFilters } from '../utils/reddit'
import { hasPro, hasStarter } from '../utils/account'


const Reddit = ({history, location, match, currentUser}) => {

  // state
  var params = getParamsFromUrl(location);
  params['keyword'] = params.keyword || '';  // make sure there's some value here

  // const [type] = useState('submissions');
  const [filters] = useState({
    'type': params.type || 'submissions',
    'limit': params.limit || '100',
    'subreddits': params.subreddits || defaultParams.subreddits,  // backend API filter
    'collection': params.collection || defaultParams.collection,
    'usernames': params.usernames || defaultParams.usernames,
    'backend': params.backend || '',//currentUser.env.default_reddit_backend,
    'sort': params.sort || defaultParams.sort,
    'timeframe': params.timeframe || defaultParams.timeframe,
    'before-timestamp': params['before-timestamp'] || defaultParams['before-timestamp'],
    'exclude-keyword': params['exclude-keyword'] || defaultParams['exclude-keyword'],
    'exclude-subreddits': params['exclude-subreddits'] || defaultParams['exclude-subreddits'],
    'exclude-usernames': params['exclude-usernames'] || defaultParams['exclude-usernames'],
    'exclude-duplicates': params['exclude-duplicates'] || defaultParams['exclude-duplicates'],
    'ids': params['ids'],
    'suggestion': params.suggestion || '',  // optional, for tracking
    'topic': params.topic || '',  // optional, for tracking
  }, [location]);
  const [results, setResults] = useState([]);
  const [savedSearch, setSavedSearch] = useState(null);
  const [aggregates, setAggregates] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [fetchingExtra, setFetchingExtra] = useState(false);
  const [fetchingAggregates, setFetchingAggregates] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [frontendFilters, setFrontendFilters] = useState({});  // {key: [value1, value2]}
  const hasParams = params && (params.keyword || params.collection || params.timeframe || params.subreddits || params.usernames || params.ids || params.topic);
  // const isEffectiveProSubscriber = hasPro(currentUser);
  const canDoSearch = currentUser.features.advanced_search;
  const isMobile = window.screen.width < 800;  // no hovering on mobile, just clicks
  // const isMobile = true;  // temp debug

  var tabs = ['Filters', 'Patterns', 'Ask'] // usually tabs are just for right side of screen
  if (isMobile){
    tabs = ['Results', ...tabs] // mobile view has everything in tabs
  }
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [selectedIds, setSelectedIds] = useState([]); // list of selected IDs, subset of results

  // effects
  useEffect(() => {
    setCurrentTab(tabs[0]); // go back to first tab if we just made a new keyword search
    fetchResults();
    document.title = `Reddit Keyword Search | ${params.keyword || 'Search Reddit Comments & Submissions'}`;
    if (document.getElementById('main-pane')){
      document.getElementById('main-pane').scrollTop = 0;
    }
    if (document.getElementById('results-pane')){
      document.getElementById('results-pane').scrollTop = 0;
    }
  }, [
    filters,
    params.keyword,
    params.ids,

    history.location.search,
    // if the above triggers a search too much, replace with the below
    // params.collection,
    // params.subreddits,
    // params.timeframe,
    // params.type,
  ]); // Run when filters or keyword changes

  // actions

  const getFilters = () => {
    // get url and filter params
    var allFilters = Object.assign(
      filters,
      {
        'keyword': params.keyword,
        'subreddits': params.subreddits, 
        'collection': params.collection,
        'usernames': params.usernames,
        'before-timestamp': params['before-timestamp'],
        'exclude-usernames': params['exclude-usernames'],
        'exclude-subreddits': params['exclude-subreddits'],
        'exclude-keyword': params['exclude-keyword'],
        'exclude-duplicates': params['exclude-duplicates'],
        'ids': params['ids'],
      }
    )

    // secret: not in url bar by default, but send to API if it's there
    if (params.limit){
      allFilters = Object.assign(allFilters, {'limit': params.limit})
    }

    // dump empty keys on certain conditions, 
    for (var key in allFilters) {
      // get rid of empty keys
      if (allFilters[key] === null || allFilters[key] === undefined || allFilters[key] === '') {
        delete allFilters[key];
      }
    }
    return allFilters
  }


  const fetchResults = () => {
    if (!hasParams) return;

    if (!canDoSearch) return;

    const allFilters = getFilters();

    // track the search
    const trackParams = Object.assign({
      "platform": "Reddit",
      "keyword": params.keyword,
      "type": params.type,
      "count_subreddits": allFilters.subreddits ? allFilters.subreddits.split(",").length : 0,
    }, allFilters)
    trackEvent("PerformConversationSearch", trackParams);

    // fetchMethod is either fetching submissions or comments, defined above
    setFetching(true);
    setError(false);
    setSelectedIds([]);
    redditService.getConversations(allFilters,
      (response) => {
        setResults(response.data.results);
        setSavedSearch(response.data.saved_search);
        fetchExtras(response.data.results);
        fetchAggregates();
        setFrontendFilters({});  // clear these as well when making a new search
        setFetching(false);

      }, (error, response) => {
        setFetching(false);
        if (error.response && error.response.status === 500){
          setError("Error fetching results from Reddit.")
        } else {
          console.log(response)
          setError(response || "Error fetching results from Reddit.")
        }
      });

    // if initial load and search bar isn't set, set it
    const searchBar = document.getElementById('search');
    if (searchBar && searchBar.value !== params.keyword) searchBar.value = params.keyword;
  }

  // gets extra data on this query, updates results
  const fetchExtras = (currentResults) => {
    
    const allFilters = getFilters();
    setFetchingExtra(true);
    redditService.getConversationExtras(allFilters,
      (response) => {
        // update responses based on the IDs
        const updates = response.data;
        var newResults = currentResults.map((r) => {
          if (updates[r.id]){
            r = Object.assign(r, updates[r.id])
          }
          return r
        });
        setResults(newResults);
        setFetchingExtra(false);
      }, (error, response) => {
        setFetchingExtra(false);
        console.error("error fetching extras", response)
      });
  }

  // gets aggregate data on this query
  const fetchAggregates = () => {
    
    const allFilters = getFilters();
    setFetchingAggregates(true);
    redditService.getConversationAggregates(allFilters,
      (response) => {
        setAggregates(response.data);
        setFetchingAggregates(false);
      }, (error, response) => {
        setFetchingAggregates(false);
        console.error("error fetching aggregates", response)
      });
  }


  // apply a backend filter, which will hit the API
  const applyNewFilters = (newFilters) => {
    var totalFilters = Object.assign(filters, newFilters) // get updated filters
    // totalFilters = Object.assign(totalFilters, {'keyword': params.keyword })// apply the keyword too

    // get rid of any empty filters
    for (var key in totalFilters) {
      if (totalFilters[key] === null || totalFilters[key] === undefined || totalFilters[key] === '') {
        delete totalFilters[key];
      }
    }

    // get rid of params that shouldn't apply to future queries
    if (totalFilters['suggestion']){ delete totalFilters['suggestion'] }

    // get rid in case they are defaults
    // if (totalFilters[key] === defaultParams[key]){
    //   delete totalFilters[key];
    // }

    // go to the new url (which should fetch new results)
    history.push(`/reddit/${toQueryString(totalFilters)}`);
  }

  const clearFilters = () => {
    // clears all API filters but the keyword
    applyNewFilters({
      'subreddits': '', 'collection': '', 'usernames': '',
      'timeframe': 'all', 'sort': 'new', 'before-timestamp': '',
      'exclude-duplicates': '', 'exclude-usernames': '', 'exclude-subreddits': '', 'exclude-keyword': ''
    })
  }

  const clearFrontendFilters = () => {
    setFrontendFilters({})
  }

  // apply a frontend filter (or several) to limit display of existing results
  const applyFrontendFilter = (key, value) => {
    // if value is already applied in that filter, remove it. otherwise create/add
    var currentValue = frontendFilters[key] || [];
    if (contains(currentValue, value)){
      currentValue = currentValue.filter(v => JSON.stringify(v) != JSON.stringify(value))
    } else {
      currentValue.push(value)
    }

    const newFilters = Object.assign(frontendFilters, {[key]: currentValue});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const setSingleFrontendFilter = (key, value) => {
    const newFilters = Object.assign(frontendFilters, {[key]: value});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const clearFrontendFilter = (key) => {
    const newFilters = Object.assign(frontendFilters, {[key]: undefined});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const toggleSelectedId = (id) => {
    if (selectedIds.includes(id)){
      setSelectedIds(selectedIds.filter(i => i !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }


  if (!hasParams){ // shouldn't really be here but just in case
    return (
      <Layout title={"Reddit Conversations"} headerTitle={'Reddit Conversations'}
        currentUser={currentUser} noPadding={true}
      >
        <EmptySearch history={history} />
      </Layout>
    )
  }

  // apply frontend filters
  var filteredResults = applyFrontendFilters(results, frontendFilters);

  return (
    <Layout title={"Reddit Conversations"} headerTitle={hasParams ? null : 'Reddit Conversations'}
      currentUser={currentUser} noPadding={true}
    >
      <ErrorBoundary>
        <Helmet><title>Conversations | Reddit</title></Helmet>

        <div className="h-full overflow-none flex flex-col">
          <div className="pl-4 sm:pl-6 pr-2 pt-2 sm:pt-4">
            <Header applyNewFilters={applyNewFilters} filters={filters} results={filteredResults} currentUser={currentUser}
              savedSearch={savedSearch} fetching={fetching} selectedIds={selectedIds} />
          </div>


          <FeaturePaywallWrapper featureKey={"reddit-search"} className={'mt-10 mx-auto overflow-auto'} 
            // hasFeature={canDoSearch || ["hot", "top"].includes(suggestion.slug)}
            currentUser={currentUser}
          >
            {isMobile ? (
              <React.Fragment>
                {tabs.length > 1 ? (
                  <nav className="px-4 sm:px-8 mt-2 flex-shrink-0 flex space-x-2 sm:space-x-4 max-w-full overflow-x-auto no-scrollbar" x-descriptions="Tab component">
                    {tabs.map((tab) => (
                      <div
                        key={tab}
                        onClick={() => setCurrentTab(tab)}
                        className={`whitespace-nowrap cursor-pointer py-2 pb-4 px-2 flex items-center border-solid border-b-2 font-medium focus:outline-none focus:ring-none text-sm ${tab === currentTab ? 'border-white text-white' : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'}`}
                      >
                        <span>{tab}</span>
                      </div>
                    ))}
                  </nav>
                ) : ''}
                
                <div id="main-pane" className={`mt-4 pl-4 sm:pl-8 pr-4 w-full`} disabled={fetching}>
                  {currentTab === "Results" ? (
                    <Results
                      results={results}
                      filteredResults={filteredResults}
                      filters={filters}
                      fetching={fetching}
                      location={location}
                      keyword={frontendFilters.phrase && frontendFilters.phrase.length ? frontendFilters.phrase.join(",") : frontendFilters.categoryKeywords && frontendFilters.categoryKeywords.length ? frontendFilters.categoryKeywords.join(",") : params.keyword}
                      frontendFilters={frontendFilters}
                      // applyFrontendFilter={applyFrontendFilter}
                      setSingleFrontendFilter={setSingleFrontendFilter}
                      applyNewFilters={applyNewFilters}
                      clearFilters={clearFilters}
                      clearFrontendFilters={clearFrontendFilters}
                      currentUser={currentUser}
                      selectedIds={selectedIds} setSelectedIds={setSelectedIds} toggleSelectedId={toggleSelectedId}
                      // className={'pr-4'}
                    />
                  ) : currentTab === "Filters" ? (
                    <Aggregates
                      results={results}
                      aggregates={aggregates}
                      filteredResults={filteredResults}
                      frontendFilters={frontendFilters}
                      applyFrontendFilter={applyFrontendFilter}
                      clearFrontendFilter={clearFrontendFilter}
                      clearFrontendFilters={clearFrontendFilters}
                      fetching={fetching}
                      fetchingExtra={fetchingExtra}
                      fetchingAggregates={fetchingAggregates}
                    />
                  ) : currentTab === "Patterns" ? (
                    <div className="">
                      <ResultsAnalyzePatterns
                        currentUser={currentUser}
                        results={filteredResults}
                        type={filters.type} // only important in case of submission-replies
                        filterParams={Object.assign(
                          filteredResults.length === results.length ? getFilters() : {'ids': filteredResults.map(r => r.id)}, // send filters if no frontend filters applied, otherwise IDs
                          {
                            'clusters_focus_keyword': filters.keyword, // ensures cluster context is always in focus for this keyword
                          }
                        )}
                        // filterParams={filteredResults.length === results.length ? getFilters() : null} // send filters if no frntend filters applied
                      />
                    </div>
                  ) : currentTab === "Ask" ? (
                    <div className="">
                      <ResultsAnalyzeQuestion
                        currentUser={currentUser}
                        results={filteredResults}
                        type={filters.type} // only important in case of submission-replies
                        filterParams={Object.assign(
                          filteredResults.length === results.length ? getFilters() : {'ids': filteredResults.map(r => r.id)}, // send filters if no frontend filters applied, otherwise IDs
                          {
                            // 'clusters_focus_keyword': filters.keyword, // ensures cluster context is always in focus for this keyword
                          }
                        )}
                        columnView={true}
                        // placeholder="Ask this audience a question..."
                        // recentSearches={recentSearches} refetchSearches={fetchRecentSearches}
                        // limitOptions={[100, 200, 500]}
                        // history={history} location={location} baseUrl={`/audience/${collection.hash}/ask/`}
                        // emptyMessage={results && results.length < 100 ? `If <100 AI results for ${suggestion.name} found, try "More > Keyword Search".` : ''}
                      />
                    </div>
                  ) : (
                    <div className="p-4">Unknown tab</div>
                  )}
                </div>


                {/* On mobile, our submissions/comments are shown in a drawer */}
                <Switch>
                  <Route path="/reddit/submission/:submissionId/"
                    render={(props) => {
                      const closeUrl = `/reddit/${props.location.search}`;
                      const submission = results && results.filter(i => i.id === props.match.params.submissionId)[0];
                      if (!submission) return ''; // just while loading
                      return (
                        <DrawerRoute closeUrl={closeUrl} {...props}>
                          <SubmissionDrawer submission={submission} params={params} filters={filters} currentUser={currentUser} />
                        </DrawerRoute>
                      );
                    }}
                  />
                  <Route path="/reddit/comment/:commentId/"
                    render={(props) => {
                      const closeUrl = `/reddit/${props.location.search}`;
                      const comment = results && results.filter(i => i.id === props.match.params.commentId)[0];
                      if (!comment) return ''; // just while loading
                      return (
                        <DrawerRoute closeUrl={closeUrl} {...props}>
                          <CommentDrawer comment={comment} params={params} filters={filters}/>
                        </DrawerRoute>
                      );
                    }}
                  />
                </Switch>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* Desktop view has results on left always, and right side toggles between Filters/Insights */}
                <div id="main-pane" className="lg:min-w-0 text-white flex overflow-auto mt-4 pl-4 sm:pl-6">

                  {/* Main results pane */}
                  <div id="results-pane" className={`flex-1 overflow-y-auto pr-4 sm:pr-8 w-1/2`} disabled={fetching}>
                    <Results
                      results={results}
                      filteredResults={filteredResults}
                      filters={filters}
                      fetching={fetching}
                      location={location}
                      keyword={frontendFilters.phrase && frontendFilters.phrase.length ? frontendFilters.phrase.join(",") : frontendFilters.categoryKeywords && frontendFilters.categoryKeywords.length ? frontendFilters.categoryKeywords.join(",") : params.keyword}
                      frontendFilters={frontendFilters}
                      // applyFrontendFilter={applyFrontendFilter}
                      setSingleFrontendFilter={setSingleFrontendFilter}
                      applyNewFilters={applyNewFilters}
                      clearFilters={clearFilters}
                      clearFrontendFilters={clearFrontendFilters}
                      currentUser={currentUser}
                      selectedIds={selectedIds} setSelectedIds={setSelectedIds} toggleSelectedId={toggleSelectedId}
                      // className={'pr-4'}
                    />
                  </div>

                  {/* Secondary view for filters or insights */}
                  <div className="secondary-pane" className={`flex-1 overflow-y-auto w-1/2`} disabled={fetching}>
                    
                    {/* In desktop view, show submission in right pane */}
                    <Switch>
                      <Route path="/reddit/submission/:submissionId/"
                        render={(props) => {
                          const closeUrl = `/reddit/${props.location.search}`;
                          const submission = results && results.filter(i => i.id === props.match.params.submissionId)[0];
                          if (!submission) return ''; // just while loading
                          return (
                            <div id="submission-drawer" className="h-full flex flex-col bg-gray-900 text-white shadow-xl overflow-auto">
                              <Helmet><title>Conversations | Reddit | Submission</title></Helmet>
                              <div className="px-4">
                                <SubmissionContents
                                  close={() => history.push(closeUrl)}
                                  keyword={params.keyword}
                                  backend={filters.backend}
                                  submission={submission}
                                  currentUser={currentUser}
                                  dontPadSubnav={true}
                                />
                              </div>
                            </div>
                          );
                        }}
                      />
                      <Route path="/reddit/comment/:commentId/"
                        render={(props) => {
                          const closeUrl = `/reddit/${props.location.search}`;
                          const comment = results && results.filter(i => i.id === props.match.params.commentId)[0];
                          if (!comment) return ''; // just while loading
                          return (
                            <div className="h-full flex flex-col bg-gray-900 text-white shadow-xl overflow-auto">
                              <Helmet><title>Conversations | Reddit | Comment</title></Helmet>
                              <div className="p-6">
                                <CommentContents
                                  close={() => history.push(closeUrl)}
                                  keyword={params.keyword}
                                  backend={filters.backend}
                                  comment={comment}
                                  commentId={comment && comment.id}
                                />
                              </div>
                            </div>
                          );
                        }}
                      />
                      {/* Otherwise render the zoomed-out views (filter & insights) */}
                      <Route path="/reddit/"
                        render={(props) => {
                          return (
                            <div className="mx-6">
                              {tabs.length > 1 && (results && results.length) ? (
                                <nav className="flex-shrink-0 mb-4 flex space-x-2 sm:space-x-4 max-w-full overflow-x-auto no-scrollbar" x-descriptions="Tab component">
                                  {tabs.map((tab) => (
                                    <div
                                      key={tab}
                                      onClick={() => setCurrentTab(tab)}
                                      className={`whitespace-nowrap cursor-pointer py-2 pb-4 px-2 flex items-center border-solid border-b-2 font-medium focus:outline-none focus:ring-none text-sm ${tab === currentTab ? 'border-white text-white' : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'}`}
                                    >
                                      <span>{tab}</span>
                                    </div>
                                  ))}
                                </nav>
                              ) : ''}
                             

                              {currentTab === "Filters" ? (
                                <Aggregates
                                  results={results}
                                  aggregates={aggregates}
                                  filteredResults={filteredResults}
                                  frontendFilters={frontendFilters}
                                  applyFrontendFilter={applyFrontendFilter}
                                  clearFrontendFilter={clearFrontendFilter}
                                  clearFrontendFilters={clearFrontendFilters}
                                  fetching={fetching}
                                  fetchingExtra={fetchingExtra}
                                  fetchingAggregates={fetchingAggregates}
                                />
                              ) : currentTab === "Patterns" ? (
                                <div className="">
                                  <ResultsAnalyzePatterns
                                    currentUser={currentUser}
                                    results={filteredResults}
                                    type={filters.type} // only important in case of submission-replies
                                    filterParams={Object.assign(
                                      filteredResults.length === results.length ? getFilters() : {'ids': filteredResults.map(r => r.id)}, // send filters if no frontend filters applied, otherwise IDs
                                      {
                                        'clusters_focus_keyword': filters.keyword, // ensures cluster context is always in focus for this keyword
                                      }
                                    )}
                                    // filterParams={filteredResults.length === results.length ? getFilters() : null} // send filters if no frntend filters applied
                                  />
                                </div>
                              ) : currentTab === "Ask" ? (
                                <div className="">
                                  <ResultsAnalyzeQuestion
                                    currentUser={currentUser}
                                    results={filteredResults}
                                    type={filters.type} // only important in case of submission-replies
                                    filterParams={Object.assign(
                                      filteredResults.length === results.length ? getFilters() : {'ids': filteredResults.map(r => r.id)}, // send filters if no frontend filters applied, otherwise IDs
                                      {
                                        // 'clusters_focus_keyword': filters.keyword, // ensures cluster context is always in focus for this keyword
                                      }
                                    )}
                                    columnView={true}
                                  />
                                </div>
                              ) : (
                                <div className="p-4">Nothing to show</div>
                              )}
                            </div>
                          );
                        }}
                      />
                    </Switch>
                  </div>  
                </div>
              </React.Fragment>
            )}
          </FeaturePaywallWrapper>

          {error ? (
            <ErrorBar error={error} setError={setError} onRetry={fetchResults} />
          ) : ''}
        </div>
      </ErrorBoundary>
    </Layout>
  );
}


export default Reddit;
