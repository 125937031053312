import React, { useState, useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Dialog, Transition, Menu, Popover, Listbox } from '@headlessui/react'
import ReactTooltip from 'react-tooltip';
import Drawer from "../common/drawer";
import { Helmet } from "react-helmet";
import {
  UserGroupIcon, CheckIcon, SelectorIcon, FilterIcon, PencilAltIcon, SparklesIcon
} from '@heroicons/react/outline'
import { CSVLink } from "react-csv";
import { QuestionTo } from '@questionto/react';

import PropsRoute from "../../components/common/propsroute";
import ErrorBar from "../../components/common/errorBar";
import NavBar from "../../components/common/navbar";
import ErrorBoundary from "../../components/common/errorboundary";
import NestedResults from "../../components/reddit/nestedresults";
import SelectPopover from "../../components/common/selectPopover";
import Aggregates from "../../components/reddit/aggregates";

import AudienceTopicResultsAnalyze from "./audienceTopicResultsAnalyze";
import AudienceTopicResultsAsk from "./audienceTopicResultsAsk";

import { SUGGESTIONS } from "./constants/suggestions";

import { redditService } from '../../services/reddit';

import { hasPro, hasStarter } from '../../utils/account';
import { trackEvent } from '../../utils/tracking';
import { toQueryString, getParamsFromUrl } from '../../utils/urls';
import { classNames } from '../../utils/tailwind';
import { getConversationCsvDict, applyFrontendFilters } from '../../utils/reddit';
import { slugify, capitalizeFirst } from '../../utils/text';
import { contains } from '../../utils/numbers';

// drawer that surfaces results for a topic in an audience
const AudienceTopicResultsDrawer = ({
  collection,
  topic,
  currentUser,
  closeModal,
  history,
  location,
  match,
}) => {
  // // state
  // const { topic } = match.params;
  // const suggestion = SUGGESTIONS.filter(s => s.slug === suggestionType)[0];
  const baseUrl = `/audience/${collection.hash}/topics/${topic.topic}/results/`;
  const urlParams = getParamsFromUrl(); // optional query params

  // get the params to show within the conversation search
  const [results, setResults] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  const [aggregates, setAggregates] = useState(null);
  const [fetchingExtra, setFetchingExtra] = useState(false);
  const [fetchingAggregates, setFetchingAggregates] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [frontendFilters, setFrontendFilters] = useState({});  // {key: [value1, value2]}
  const [selectedIds, setSelectedIds] = useState([]);  // id of submission/comments

  // const [selectedKeyword, setSelectedKeyword] = useState(suggestionKeywords[0] || null);
  const sortOptions = ["Recent", "Upvotes", "Comments"]
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  
  const isEffectiveProSubscriber = hasPro(currentUser);
  const isEffectiveStarterSubscriber = hasStarter(currentUser);

  // effects
  useEffect(() => {
    if (topic){
      fetchAllResults();
    }
  }, []); // Fetch results whenever change keyword

  // actions
  const getResultParams = () => {
    // based on the suggestion type, get the query params
    var params = {
      'type': 'submissions', // only submissions on suggestion page
      'collection': collection.hash,
      'topic': topic.topic
    }

    // append subreddit/theme params if we have them
    if (urlParams.subreddits){
      params = Object.assign(params, {'subreddits': urlParams.subreddits})
    }
    if (urlParams.suggestion){
      params = Object.assign(params, {'suggestion': urlParams.suggestion})
    }
    if (urlParams.limit){
      params = Object.assign(params, {'limit': urlParams.limit})
    }

    return params
  }

  const fetchAllResults = () => {
    // fetch results based on the suggestion
    const params = getResultParams()

    // track the search
    // TODO: add the subreddit/theme here too
    const trackParams = Object.assign({
      "platform": "Reddit",
      "topic": topic.topic,
        // "keyword": params.keyword || '',
      // "count_subreddits": params.subreddits ? params.subreddits.split(",").length : 0,
    }, params)
    trackEvent("PerformConversationSearch", trackParams);

    // fetchMethod is either fetching submissions or comments, defined above
    // delete params['type']; // don't need this guy in the actual params
    setFetching(true);
    setError(false);
    redditService.getConversations(params,
      (response) => {
        setResults(response.data.results);
        fetchExtras(response.data.results);
        // fetchAggregates();
        setFetching(false);
      }, (error, response) => {
        setFetching(false);
        if (error.response && error.response.status === 500){
          setError("Error fetching results from Reddit.")
        } else {
          setError(response || "Error fetching results from Reddit.")
        }
      });
  }

  // gets extra data on this query, updates results
  const fetchExtras = (currentResults) => {
    
    const allFilters = getResultParams();
    setFetchingExtra(true);
    redditService.getConversationExtras(allFilters,
      (response) => {
        // update responses based on the IDs
        const updates = response.data;
        var newResults = currentResults.map((r) => {
          if (updates[r.id]){
            r = Object.assign(r, updates[r.id])
          }
          return r
        });
        setResults(newResults);
        setFetchingExtra(false);
      }, (error, response) => {
        setFetchingExtra(false);
        console.error("error fetching extras", response)
      });
  }

  // gets aggregate data on this query
  const fetchAggregates = () => {
    // not fetching right now

    // const allFilters = getResultParams();
    // setFetchingAggregates(true);
    // redditService.getConversationAggregates(allFilters,
    //   (response) => {
    //     setAggregates(response.data);
    //     setFetchingAggregates(false);
    //   }, (error, response) => {
    //     setFetchingAggregates(false);
    //     console.error("error fetching aggregates", response)
    //   });
  }

  const clearFrontendFilters = () => {
    setFrontendFilters({})
  }

  // apply a frontend filter (or several) to limit display of existing results
  const applyFrontendFilter = (key, value) => {
    // if value is already applied in that filter, remove it. otherwise create/add
    var currentValue = frontendFilters[key] || [];
    if (contains(currentValue, value)){
      currentValue = currentValue.filter(v => JSON.stringify(v) != JSON.stringify(value))
    } else {
      currentValue.push(value)
    }

    const newFilters = Object.assign(frontendFilters, {[key]: currentValue});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const clearFrontendFilter = (key) => {
    const newFilters = Object.assign(frontendFilters, {[key]: undefined});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const toggleSelectedId = (id) => {
    if (selectedIds.includes(id)){
      setSelectedIds(selectedIds.filter(i => i !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  // filter results
  var filteredResults = results || [];

  // sort results
  if (sortOption === "Upvotes"){
    filteredResults = filteredResults.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
  } else if (sortOption === "Comments"){
    filteredResults = filteredResults.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
  } else if (!sortOption || sortOption === "Recent"){
    // should be default
    filteredResults = filteredResults.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
  }

  // tabs to display
  const countResults = results && results.length;
  const countFilteredResults = filteredResults.length;
  var tabs = [
    { name: `Results`, link: `${baseUrl}`, exact: true},
    { name: 'Patterns', link: `${baseUrl}patterns/`, 'icon': SparklesIcon},
    { name: 'Ask', link: `${baseUrl}ask/`, 'icon': SparklesIcon},
  ]

  // highlighted keyword
  var keywordToHighlight = topic.topic
  // if (frontendFilters.categoryKeywords && frontendFilters.categoryKeywords.length){
  //   keywordToHighlight = `${frontendFilters.categoryKeywords.join(",")},${keywordToHighlight}`
  // }
  // if (frontendFiltersToUse.phrase && frontendFiltersToUse.phrase.length){
  //   keywordToHighlight = `${frontendFiltersToUse.phrase.join(",")},${keywordToHighlight}`
  // }
  // TODO: if keywordToHighlight if originally blank, we have extra ","

  const topicContext = `People discussing ${topic.topic} in ${urlParams.subreddits ? `r/${urlParams.subreddits}` : `${collection.name} communities`}`

  return (
    <Drawer close={closeModal}>
      {topic ? (
        <ErrorBoundary>
          <div className="h-full flex flex-col">
            <Helmet><title>Audience | Topic | {topic.topic} | Results</title></Helmet>

            <div className="p-4 pb-2">
              <div className="flex items-start justify-between">
                <div className="font-medium">
                  <div className="text-lg flex items-center">
                    {capitalizeFirst(topic.topic)}
                  </div>
                  <div className="text-sm opacity-50">{topicContext}</div>
                </div>

                <div className="ml-3 h-7 flex items-center space-x-2">
                  <Popover className="relative">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center ${open ? 'text-white' : ''}`}
                        >
                          
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                          </svg>
                          <span className="">More</span>
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute z-10 right-12 top-0 px-2 w-screen max-w-xs sm:px-0"
                          >
                            <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white text-gray-900 p-4">

                                <div onClick={closeModal}
                                  className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                  <div className="ml-4">
                                    <p className="font-medium text-gray-700">Close</p>
                                  </div>
                                </div>

                                <Link to={`/reddit/${toQueryString(getResultParams())}`}
                                  // disabled={!isEffectiveProSubscriber}
                                  className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                                  </svg>
                                  <div className="ml-4 flex items-center">
                                    <p className="font-medium text-gray-700">Advanced Search UI</p>
                                    {!isEffectiveStarterSubscriber ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-5 h-5 text-yellow-400">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                      </svg>
                                    ) : ''}
                                  </div>
                                </Link>
                                {currentUser.features.download_csv ? (
                                  <CSVLink
                                    // data={getConversationCsvDict(results || [])}
                                    data={getConversationCsvDict(filteredResults && filteredResults.filter(r => !selectedIds.length || selectedIds.includes(r.id)) || [])}
                                    filename={collection ? `${slugify(collection.name + '-' + topic.topic)}.csv` : "gummysearch-download.csv"}
                                    // className="btn btn-primary"
                                    target="_blank"
                                    onClick={close}
                                    disabled={!results || !results.length || !currentUser.features.download_csv}
                                    className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                    <div className="ml-4 flex items-center">
                                      <p className="font-medium text-gray-700">Download</p>
                                    </div>
                                  </CSVLink>
                                ) : ''}
                              </div>
                              
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <button
                    className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center space-x-1"
                    onClick={closeModal}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <span className="">Close</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="flex-grow overflow-hidden flex flex-col">
              <div className="px-4 flex items-center flex-wrap border-b border-solid border-gray-800">
                <NavBar tabs={tabs}/>

                <div className="ml-auto py-2">
                  {/*<div className="p-2 mb-2 border-b border-solid border-gray-800"></div>*/}
                  <div className="flex items-center">

                    {selectedIds && selectedIds.length ? (
                      <div className="ml-4 flex items-center">
                        <div className="text-xs opacity-50">{selectedIds.length} Selected</div>

                        <div className="ml-1 flex items-center space-x-1">
                          <ReactTooltip id={`selected-icons`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                          
                          <span onClick={() => setSelectedIds([])} data-for={'selected-icons'} data-tip="Clear">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-300 hover:text-gray-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </span>

                          <CSVLink
                            // data={getConversationCsvDict(results || [])}
                            data={getConversationCsvDict(filteredResults && filteredResults.filter(r => !selectedIds.length || selectedIds.includes(r.id)) || [])}
                            filename={collection ? `${slugify(collection.name + '-' + topic.topic)}.csv` : "gummysearch-download.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            // disabled={!results || !results.length || !isProSubscriber}
                            disabled={!results || !results.length} // add back isProSubscriber check once we have other options (save to list)
                            // className="cursor-pointer"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg"  data-for={'selected-icons'} data-tip="CSV Download" className="h-5 w-5 text-gray-300 hover:text-gray-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>
                          </CSVLink>
                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>
              </div>

              <div className="overflow-auto h-full">
                <Switch>
                  <PropsRoute path={`${baseUrl}patterns/`} component={AudienceTopicResultsAnalyze}
                    currentUser={currentUser}
                    results={filteredResults}
                    filterParams={getResultParams()}
                    topic={topic}
                    topicContext={topicContext}
                    fetching={fetching}
                    collection={collection}
                  />
                  <PropsRoute path={`${baseUrl}ask/`} component={AudienceTopicResultsAsk}
                    currentUser={currentUser}
                    results={filteredResults}
                    filterParams={getResultParams()}
                    topic={topic}
                    fetching={fetching}
                    collection={collection}
                  />
                  <Route path={`${baseUrl}`}
                    render={(props) => {
                      return (
                        <NestedResults fetching={fetching} results={filteredResults} keyword={keywordToHighlight}
                          baseUrl={`${baseUrl}`} location={location} history={history} currentUser={currentUser}
                          selectedIds={selectedIds} setSelectedIds={setSelectedIds}
                          // toggleSelectedId={toggleSelectedId}
                          showHeader={true}
                          inlineScroll={true}
                          sortOptions={sortOptions}
                          sortOption={sortOption} setSortOption={setSortOption}
                        />
                      );
                    }}
                  />
                  
                  <Redirect path={`${baseUrl}`} to={`${baseUrl}`} exact />
                </Switch>
              </div>
            </div>

            {error ? (
              <ErrorBar error={error} setError={setError} onRetry={fetchAllResults} />
            ) : ''}   
              
          </div>
        </ErrorBoundary>  
      ) : (
        <div className="bg-red-500 text-white p-4">Invalid Topic</div>
      )}
    </Drawer>
  );
};


export default AudienceTopicResultsDrawer;

