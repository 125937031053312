import React, { useState, useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Dialog, Transition, Menu, Popover } from '@headlessui/react'
import ReactTooltip from 'react-tooltip';
import Drawer from "../common/drawer";
import { Helmet } from "react-helmet";
import {
  UserGroupIcon, CheckIcon, SelectorIcon, SparklesIcon
} from '@heroicons/react/outline'
import { CSVLink } from "react-csv";

import PropsRoute from "../../components/common/propsroute";
import ErrorBar from "../../components/common/errorBar";
import ErrorBoundary from "../../components/common/errorboundary";
import NestedResults from "../../components/reddit/nestedresults";
import NavBar from "../../components/common/navbar";
import SaveButton from "../../components/conversations/savebutton";
import Aggregates from "../../components/reddit/aggregates";
import SelectPopover from "../../components/common/selectPopover";
import FeaturePaywallWrapper from "../../components/account/featurePaywallWrapper"
import AudienceBrowseAnalyze from "./audiencebrowseanalyze";
import AudienceBrowseAsk from "./audienceBrowseAsk";

import { redditService } from '../../services/reddit';

import { SUGGESTIONS } from "./constants/suggestions";
import { hasPro, hasStarter } from '../../utils/account';
import { trackEvent } from '../../utils/tracking';
import { toQueryString, getParamsFromUrl } from '../../utils/urls';
import { classNames } from '../../utils/tailwind';
import { getConversationCsvDict, applyFrontendFilters } from '../../utils/reddit';
import { slugify } from '../../utils/text';
import { contains } from '../../utils/numbers';


const AudienceBrowseDrawer = ({
  collection,
  currentUser,
  baseUrl, // looks like `/audience/${collection.hash}/topics/search/` but could be "themes" instead of "topics"
  closeModal,
  history,
  location,
  match,
}) => {
  // // state
  var params = getParamsFromUrl(location);

  // get the params to show within the conversation search
  const [results, setResults] = useState(null);
  const [fetching, setFetching] = useState(true);
  // const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [keyword, setKeyword] = useState(params.keyword || '');
  const [suggestion, setSuggestion] = useState(params.suggestion || ''); // for tracking purposes (if redirected). keep in mind will stay in tracking params until drawer closes
  const suggestionSearched = SUGGESTIONS.filter(s => s.slug === suggestion)[0];
  const [searchedKeyword, setSearchedKeyword] = useState(params.keyword || '');
  const [searchedParams, setSearchedParams] = useState({});
  const [existingSavedSearch, setExistingSavedSearch] = useState(null);
  const sortOptions = ['Recent', 'Upvotes', 'Comments']
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  
  const [aggregates, setAggregates] = useState(null);
  const [fetchingExtra, setFetchingExtra] = useState(false);
  const [fetchingAggregates, setFetchingAggregates] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [frontendFilters, setFrontendFilters] = useState({});  // {key: [value1, value2]}
  const [selectedIds, setSelectedIds] = useState([]);  // id of submission/comments

  const isEffectiveProSubscriber = hasPro(currentUser);
  const isEffectiveStarterSubscriber = hasStarter(currentUser);


  // effects
  useEffect(() => {
    if (keyword){
      fetchAllResults();
    }
  }, []); // Fetch upon load if we have a keywrod

  // effects


  // actions
  const getResultParams = () => {
    // based on the suggestion type, get the query params
    var params = {
      'type': 'submissions', // only submissions on suggestion page
      'collection': collection.hash,
      'keyword': keyword,
      'suggestion': suggestion,
    }
    return params
  }

  const fetchAllResults = (e) => {

    if (e) e.preventDefault();
    if (!keyword) return;

    // if (!isEffectiveStarterSubscriber){
    //   // don't fetch results if we don't have starter, pro, or on free trial
    //   return;
    // }

    // make sure nav matches
    history.push(`${baseUrl}?keyword=${keyword}`)

    const params = getResultParams()

    // track the search
    const trackParams = Object.assign({
      "platform": "Reddit",
      "keyword": params.keyword || '',
      "suggestion": params.suggestion || '',
    }, params)
    trackEvent("PerformConversationSearch", trackParams);

    // fetch results for keyword
    setFetching(true);
    setError(false);
    redditService.getConversations(params,
      (response) => {
        setSearchedKeyword(params.keyword); // keep track of what we have results for (as opposed to whats in input)
        setSearchedParams(params);
        setExistingSavedSearch(response.data.saved_search)
        setResults(response.data.results);
        fetchExtras(response.data.results);
        fetchAggregates();
        setFetching(false);
      }, (error, response) => {
        setFetching(false);
        if (error.response && error.response.status === 500){
          setError("Error fetching results from Reddit.")
        } else {
          setError(response || "Error fetching results from Reddit.")
        }
      });
  }

  // gets extra data on this query, updates results
  const fetchExtras = (currentResults) => {
    
    const allFilters = getResultParams();
    setFetchingExtra(true);
    redditService.getConversationExtras(allFilters,
      (response) => {
        // update responses based on the IDs
        const updates = response.data;
        var newResults = currentResults.map((r) => {
          if (updates[r.id]){
            r = Object.assign(r, updates[r.id])
          }
          return r
        });
        setResults(newResults);
        setFetchingExtra(false);
      }, (error, response) => {
        setFetchingExtra(false);
        console.error("error fetching extras", response)
      });
  }

  // gets aggregate data on this query
  const fetchAggregates = () => {
    
    const allFilters = getResultParams();
    setFetchingAggregates(true);
    redditService.getConversationAggregates(allFilters,
      (response) => {
        setAggregates(response.data);
        setFetchingAggregates(false);
      }, (error, response) => {
        setFetchingAggregates(false);
        console.error("error fetching aggregates", response)
      });
  }

  const clearFrontendFilters = () => {
    setFrontendFilters({})
  }

  // apply a frontend filter (or several) to limit display of existing results
  const applyFrontendFilter = (key, value) => {
    // if value is already applied in that filter, remove it. otherwise create/add
    var currentValue = frontendFilters[key] || [];
    if (contains(currentValue, value)){
      currentValue = currentValue.filter(v => JSON.stringify(v) != JSON.stringify(value))
    } else {
      currentValue.push(value)
    }

    const newFilters = Object.assign(frontendFilters, {[key]: currentValue});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const clearFrontendFilter = (key) => {
    const newFilters = Object.assign(frontendFilters, {[key]: undefined});
    setFrontendFilters(Object.assign({}, newFilters));  // copy to ensure render happens
  }

  const toggleSelectedId = (id) => {
    if (selectedIds.includes(id)){
      setSelectedIds(selectedIds.filter(i => i !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }


  // render utils

  // filter results based on frontend filters
  var filteredResults = applyFrontendFilters(results || [], frontendFilters);

  // pro users see the aggregate tab
  const countResults = results && results.length;
  const countFilteredResults = filteredResults.length;
  var tabs = [
    { name: `Results`, link: `${baseUrl}`, exact: true },
    // { name: 'Filters', link: `${baseUrl}aggregate/`, disabled: isEffectiveStarterSubscriber ? false : true },
    { name: 'Patterns', link: `${baseUrl}patterns/`, 'icon': SparklesIcon},
    { name: 'Ask', link: `${baseUrl}ask/`, 'icon': SparklesIcon},
  ]

  // sort results
  if (sortOption === "Upvotes"){
    filteredResults = filteredResults.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
  } else if (sortOption === "Comments"){
    filteredResults = filteredResults.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
  } else if (!sortOption || sortOption === "Recent"){
    // should be default
    filteredResults = filteredResults.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
  }

  // highlighted keyword
  var keywordToHighlight = searchedKeyword || '';
  if (frontendFilters.categoryKeywords && frontendFilters.categoryKeywords.length){
    keywordToHighlight = `${frontendFilters.categoryKeywords.join(",")},${keywordToHighlight}`
  }
  if (frontendFilters.phrase && frontendFilters.phrase.length){
    keywordToHighlight = `${frontendFilters.phrase.join(",")},${keywordToHighlight}`
  }

  const keywordContext = `People discussing ${keyword} in ${collection.name} communities}`

  return (
    <Drawer close={closeModal}>
      <ErrorBoundary>
        <div className="h-full flex flex-col">
          <Helmet><title>Audience | Browse</title></Helmet>

          <div className="p-4">
            <div className="flex items-start justify-between">
              <div className="font-medium">
                <div className="text-lg flex items-center">
                  Keyword Search
                  <span className="opacity-50 mx-2 text-sm">in</span>
                  <span className="opacity-50 mr-1">
                    <UserGroupIcon className="h-4 w-4" aria-hidden="true" />
                  </span>
                  {collection.name}
                </div>
                {suggestion && suggestionSearched ? (
                  <div className="text-sm opacity-50 flex items-center">{suggestionSearched.name} matches are keyword-based for custom audiences.</div>
                ) : ''}
                
              </div>

              <div className="ml-3 h-7 flex items-center space-x-2">


                <Popover className="relative">
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={`rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center ${open ? 'text-white' : ''}`}
                      >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                        <span className="">More</span>
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 right-12 top-0 px-2 w-screen max-w-xs sm:px-0"
                        >
                          <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white text-gray-900 p-4">

                              <div onClick={closeModal}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <div className="ml-4">
                                  <p className="font-medium text-gray-700">Close</p>
                                </div>
                              </div>

                              <Link to={`/reddit/${toQueryString(getResultParams())}`}
                                // disabled={!isEffectiveProSubscriber}
                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                                </svg>
                                <div className="ml-4 flex items-center">
                                  <p className="font-medium text-gray-700">Advanced Search</p>
                                  {!isEffectiveStarterSubscriber ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-5 h-5 text-yellow-400">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                  ) : ''}
                                </div>
                              </Link>

                              {currentUser.features.download_csv ? (
                                <CSVLink
                                  // data={getConversationCsvDict(results || [])}
                                  data={getConversationCsvDict(filteredResults && filteredResults.filter(r => !selectedIds.length || selectedIds.includes(r.id)) || [])}
                                  filename={collection ? `${slugify(collection.name + '-' + keyword)}.csv` : "gummysearch-download.csv"}
                                  // className="btn btn-primary"
                                  target="_blank"
                                  onClick={close}
                                  disabled={!results || !results.length || !currentUser.features.download_csv}
                                  className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                  </svg>
                                  <div className="ml-4 flex items-center">
                                    <p className="font-medium text-gray-700">Download</p>
                                  </div>
                                </CSVLink>
                              ) : ''}
                              
                            </div>
                            
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <button
                  className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center space-x-1"
                  onClick={closeModal}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  <span>Close</span>
                </button>
                
              </div>
            </div>
          </div>

          <div className="pl-4 px-2">
            <form className="flex items-center" onSubmit={fetchAllResults}>
              <input
                type="text"
                name="keyword"
                autoComplete="off" autoFocus="off"
                className={`bg-gray-800 border-gray-700 placeholder-gray-400 text-white block text-black w-full sm:text-sm border-gray-300 rounded-md ${!keyword ? 'focus:ring-red-500 focus:border-red-500' : 'focus:ring-cyan-500 focus:border-cyan-500'}`}
                placeholder={`Try a keyword related to your business`}
                value={keyword} onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
              <button
                type="submit"
                className="ml-2 text-center px-4 py-2 border border-solid border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
              >
                Browse
              </button>
            </form>
          </div>

          <FeaturePaywallWrapper featureKey={"audience-search"} className={'mt-10 mx-auto overflow-auto'} 
            // hasFeature={isEffectiveStarterSubscriber || ["hot", "top"].includes(suggestion.slug)}
            currentUser={currentUser}
          >
            {!isEffectiveProSubscriber ? (
              <ReactTooltip id={`track-disabled`} place="left" effect="solid" backgroundColor="white" textColor="black" />
            ) : ''}
            
            {keyword ? (
              <div className="flex-grow overflow-hidden flex flex-col">
                <div className="px-4 mt-2 flex items-center flex-wrap border-b border-solid border-gray-800">
                  <NavBar tabs={tabs}/>

                  <div className="ml-auto py-2 flex items-center">

                    {selectedIds && selectedIds.length ? (
                      <div className="ml-4 flex items-center">
                        <div className="text-xs opacity-50">{selectedIds.length} Selected</div>

                        <div className="ml-1 flex items-center space-x-1">
                          <ReactTooltip id={`selected-icons`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                          
                          <span onClick={() => setSelectedIds([])} data-for={'selected-icons'} data-tip="Clear">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-300 hover:text-gray-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </span>

                          {currentUser.features.download_csv ? (
                            <CSVLink
                              // data={getConversationCsvDict(results || [])}
                              data={getConversationCsvDict(filteredResults && filteredResults.filter(r => !selectedIds.length || selectedIds.includes(r.id)) || [])}
                              filename={collection ? `${slugify(collection.name + '-' + keyword)}.csv` : "gummysearch-download.csv"}
                              // className="btn btn-primary"
                              target="_blank"
                              // disabled={!results || !results.length || !isProSubscriber}
                              disabled={!results || !results.length} // add back isProSubscriber check once we have other options (save to list)
                              className="cursor-pointer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg"  data-for={'selected-icons'} data-tip="CSV Download" className="h-5 w-5 text-gray-300 hover:text-gray-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                              </svg>
                            </CSVLink>
                          ) : ''}
                        </div>
                      </div>
                    ) : ''}

                  </div>
                  

                  {!suggestion && searchedKeyword && !searchedKeyword.includes(',') ? (
                    <div className="ml-2" data-for={'track-disabled'} data-tip="Keyword Tracking is available on GummySearch Pro subscription">
                      <SaveButton
                        // onClick={(e) => {
                        //   if (!currentUser.features.tracked_keywords){
                        //     e.preventDefault();
                        //   }
                        // }}
                        // disabled={!currentUser.features.tracked_keywords} // if they save but don't have, it will alert them
                        audienceName={collection.name}
                        fetching={fetching || (keyword !== searchedKeyword)}
                        params={searchedParams}
                        existingSavedSearch={existingSavedSearch}
                      />
                    </div>
                  ) : ''}
                </div>

                <Switch>
                  {/*<Route path={`${baseUrl}aggregate/`}
                    render={(props) => {
                      return (
                        <div id="aggregates-pane" className="overflow-y-auto px-6 py-4" disabled={fetching}>
                          <Helmet><title>Audience | Browse | Aggregate</title></Helmet>
                          <Aggregates
                            results={results}
                            aggregates={aggregates}
                            filteredResults={filteredResults}
                            frontendFilters={frontendFilters}
                            applyFrontendFilter={applyFrontendFilter}
                            clearFrontendFilter={clearFrontendFilter}
                            fetching={fetching}
                            fetchingExtra={fetchingExtra}
                            fetchingAggregates={fetchingAggregates}
                            hideTitle={true}
                            // hideSections={['hot', 'top'].includes(suggestion.slug) ? ['velocity_frequency'] : []}
                            // keyword={params.keyword}
                            // velocity={results && results.velocity}
                            // allKeywords={results && results.keywords}
                          />
                        </div>
                      );
                    }}
                  />*/}
                  
                  <PropsRoute path={`${baseUrl}patterns/`} component={AudienceBrowseAnalyze}
                    currentUser={currentUser}
                    results={filteredResults}
                    filterParams={getResultParams()}
                    fetching={fetching}
                    collection={collection}
                    keywordContext={keywordContext}
                  />
                  <PropsRoute path={`${baseUrl}ask/`} component={AudienceBrowseAsk}
                    currentUser={currentUser}
                    results={filteredResults}
                    filterParams={getResultParams()}
                    fetching={fetching}
                    collection={collection}
                  />
                  <Route path={`${baseUrl}`}
                    render={(props) => {
                      return (
                        <NestedResults fetching={fetching} results={filteredResults} keyword={keywordToHighlight}
                          baseUrl={baseUrl} location={location} history={history} currentUser={currentUser}
                          selectedIds={selectedIds} setSelectedIds={setSelectedIds}
                          // toggleSelectedId={toggleSelectedId}

                          showHeader={true}
                          sortOptions={sortOptions} sortOption={sortOption} setSortOption={setSortOption}
                        />
                      );
                    }}
                  />

                  {/*<Redirect path={`${baseUrl}`} to={`${baseUrl}`} exact />*/}

                </Switch>
              </div>
            ) : ''}
          </FeaturePaywallWrapper>

          {error ? (
            <ErrorBar error={error} setError={setError} onRetry={fetchAllResults} />
          ) : ''}   
            
        </div>
      </ErrorBoundary>  
    </Drawer>
  );
};


export default AudienceBrowseDrawer;


