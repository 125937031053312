import React, { useState, useEffect, useRef } from "react"
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';
import { Popover, Transition } from '@headlessui/react'
import { ModalRoute, ModalContainer } from 'react-router-modal';

import {
  InformationCircleIcon, LinkIcon, ExternalLinkIcon, LightBulbIcon
} from '@heroicons/react/outline'


import Layout from "../components/layout";
// import ErrorBar from "../components/common/errorBar";
import LoaderFancy from "../components/common/loaderfancy";
// import ErrorBoundary from "../components/common/errorboundary";

// import Browse from "../components/conversations/browse";
// import Content from "../components/guide/content";
import Browse from "../components/conversations/browse";
import TrialDetails from "../components/guide/trial";
import AccountDetails from "../components/account/subscriptionDetails";
// import Checklist from "../components/guide/checklist";
import Knowedgebase from "../components/guide/knowledgebase";
import ExperimentsToggle from "../components/guide/experimentstoggle";
import SearchInfoModal from "../components/search/searchInfoModal";
import SearchQueryModal from "../components/search/searchQueryModal";

import { profileService } from '../services/profile';
import { getIcon } from '../utils/icons';
import { objectiveOptions, learningStyleOptions } from '../components/welcome/constants';



const Search = ({history, location, match, currentUser}) => {
  
  var tips = [
    {'title': 'Read guide on helpful keywords to search for outbound opportunities', 'link': 'https://gummysearch.com/insights/keywords-to-track-on-reddit/'},
    {'title': 'Search within an audience for more relevant keyword results', 'link': ''},
    {'title': "After searching, use visual filters to further filter down results", 'link': ''},
    {'title': "Bookmark useful conversations you come across", 'link': ''},
    {'title': `Press the "Track" button to be notified of new keyword matches`, 'link': ''},
    {'title': "Use the patterns feature to summarize Reddit posts in bulk", 'link': ''},
  ]
  // if (currentUser && !currentUser.subscription){
  //   tips = [
  //     {'title': 'Advanced Search is available to Starter plan users', 'link': '/account/subscription/select/'},
  //     ...tips
  //   ]
  // }

  return (
    <Layout currentUser={currentUser}>
      <Helmet><title>Search</title></Helmet>

      <div className="flex items-center sm:-mt-3 mb-6 pb-5 border-b  border-solid border-gray-800">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Advanced Search</h2>
        </div>

        <Link to={`/search/info/`}
          type="button"
          className="appearance-none ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
        >
          <InformationCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Info
        </Link>
      </div>

      <div className="w-full mx-auto justify-center">
        
        <div className="mb-10">
          <Browse history={history} />
        </div>

        <div className="mx-auto pt-4 max-w-3xl border-t border-gray-800">
          <div className="">
            <label className="text-lg font-medium mb-1">
              Search Tips
            </label>

            <div>
              {tips.map(t => (
                <div key={t.title} className="bg-gray-800 p-2 sm:p-4 rounded-md mb-2 flex items-center">
                  <LightBulbIcon className="h-6 w-6 mr-2 opacity-50 flex-shrink-0" aria-hidden="true" />
                  <div className="text-sm sm:text-base text-gray-300">{t.title}</div>

                  {t.link ? (
                    <a href={t.link} target="_blank" className="ml-auto pl-2">
                      <ExternalLinkIcon className="h-6 w-6 opacity-80 hover:opacity-100"/>
                    </a>
                  ) : ''}
                  
                </div>
              ))}
              
            </div>
          </div>
        </div>
      </div>

      <ModalRoute
        path={`/search/info/`}
        parentPath={`/search/`}
        component={SearchInfoModal}
        props={{
          'currentUser': currentUser,
        }}
      />
      <ModalRoute
        path={`/search/query/`}
        parentPath={`/search/`}
        component={SearchQueryModal}
        props={{
          'currentUser': currentUser,
        }}
      />
      <ModalContainer />
      
    </Layout>
  );
}


export default Search
