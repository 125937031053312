import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux';
import { Popover, Transition } from '@headlessui/react'

import { savedSearchesService } from '../../services/savedSearches';

// based on the search type + params, either shows user option to save the search
// or of it already exists, displays that and allows user to modify query?
const SaveButton = ({ params, fetching, existingSavedSearch, audienceName, disabled, currentUser, audiences }) => {
  // state
  const [savedSearch, setSavedSearch] = useState(existingSavedSearch);  // could be empty and filled in later
  const [savePopoverOpen, setSavePopoverOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultName, setDefaultName] = useState('');
  const [aiMatchCriteria, setAiMatchCriteria] = useState('');
  
  // effects
  useEffect(() => {
    setSavedSearch(existingSavedSearch)
  }, [
    existingSavedSearch
  ]); // If we have an existing saved search for the current query, update the state

  useEffect(() => {
    setDefaultName(getDefaultSearchName())
    setSavedSearch(null)
  }, [
    params.keyword,
    params.collection,
    params.subreddits,
    params.sort,
  ]); // set an initial name for the saved search, and clear any saved search we got


  // actions
  const saveSearch = (name) => {

    var data = {
      'name': name,
      'params': params,
    }

    // get the type from the params
    if (params.type == "submissions"){
      data.search_type = 'RS'
    } else if (params.type == "comments"){
      data.search_type = 'RC'
    } else {
      alert("Cant save tracked keyword for this search type");
      return;
    }

    // add the ai match criteria if it's enabled
    if (currentUser.features.tracked_keyword_ai_criteria){
      data.match_criteria = {'include': aiMatchCriteria};
    }

    // get the params to save (all params but the sort + timeframe, as we're always getting newest results)
    // TODO: won't match it if it's missing those params

    // go saves
    setLoading(true);
    savedSearchesService.createSavedSearch(data, (results) => {
      if (results && results.data){
        setLoading(false)
        setSavedSearch(results.data);
      }
    }, (response, error) => {
      console.log(error)
      setLoading(false)
      alert(error || "Server Error")
    })
  }

  const getDefaultSearchName = () => {
    var initialSearchName = params.keyword || params.usernames || params.sort || 'unkown term';
    if (params.collection && audienceName){
      initialSearchName += ` in ${audienceName}`
    } else if (params.collection && audiences && audiences.length > 0){
      initialSearchName += ` in ${audiences.filter(a => a.hash == params.collection)[0]?.name || 'audience'}`
    } else if (params.subreddits){
      initialSearchName += ` in ${params.subreddits}`
    }
    return initialSearchName;
  }

  return (
    <div disabled={fetching || disabled}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 rounded-md w-full block" disabled={loading}>
              <div id="track-keyword-button" className={`text-sm font-medium rounded-md py-1 px-2 ${savedSearch ? 'tracking hover:bg-gray-700 text-gray-300' : !fetching ? 'hover:bg-gray-700 text-gray-300' : 'text-gray-300'}`}>
                {/*<svg className={`h-6 w-6 mx-auto`} xmlns="http://www.w3.org/2000/svg" fill={savedSearch ? 'rgba(16, 185, 129, 1)' : 'rgba(8, 145, 178,0.5)'} viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                </svg>*/}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill={savedSearch ? 'rgba(16, 185, 129, 1)' : 'rgba(8, 145, 178,0.5)'} viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                <div className="text-xs">{savedSearch ? 'Tracking' : 'Track'}</div>
              </div>
            </Popover.Button>

            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className={`absolute z-10 right-full mr-2 top-0 px-2 w-screen max-w-xs sm:px-0`}
              >
                {disabled ? (
                  <div className="relative grid bg-gray-700 text-white p-2 rounded-lg shadow-lg border border-gray-800">
                    <div className="pt-6 relative -top-4">
                      <hr className="opacity-10" />
                      <div className="absolute top-3 w-full text-center overflow-hidden">
                        <span className="p-1 text-xs rounded-sm bg-gray-700 text-gray-300 uppercase whitespace-nowrap overflow-ellipsis">
                          <span>Keyword tracking</span>
                        </span>
                      </div>
                    </div>
                    <div className="mb-2">
                      <p>Keyword tracking is available on the Starter plan. To update your plan, please go to your <Link to={`/account/subscription/select/`} className="underline">subscription settings</Link>.</p>
                    </div>
                  </div>
                ) : savedSearch ? (
                  <div className="relative grid bg-gray-700 text-white p-2 rounded-lg shadow-lg border border-gray-800">
                    <div className="pt-6 relative -top-4">
                      <hr className="opacity-10" />
                      <div className="absolute top-3 w-full text-center overflow-hidden">
                        <span className="p-1 text-xs rounded-sm bg-gray-700 text-gray-300 uppercase whitespace-nowrap overflow-ellipsis">
                          <span>{savedSearch.name}</span>
                        </span>
                      </div>
                    </div>
                    <div className="text-sm">
                      <p>This keyword is tracked! You'll see notifications for new matching conversations.</p><br/>
                      <p>Feel free to add more, and manage all of your tracked keywords on the <Link to={`/conversations/`} className="underline">conversations page</Link>.</p>
                    </div>
                  </div>
                ) : (
                  <div className="relative grid bg-gray-700 text-white p-2 rounded-lg shadow-lg border border-gray-800">
                    <div className="pt-6 relative -top-4">
                      <hr className="opacity-10" />
                      <div className="absolute top-3 w-full text-center">
                        <span className="p-1 text-xs rounded-sm bg-gray-700 text-gray-300 uppercase">
                          <span>Track Keyword</span>
                        </span>
                      </div>
                    </div>
                    <div className="text-sm mb-4">
                      <p>Tracked keywords will keep you up to date on new conversations for this search.</p>
                    </div>

                    <div className="text-xs uppercase font-semibold opacity-50 mb-1">Name your search</div>
                    
                    <input id="saved-search-name" type="text"
                      className="bg-gray-800 p-2 text-sm border-none rounded-md w-full focus:ring-0"
                      placeholder={loading ? "Saving..." : "Name this search"} autoComplete="off" spellCheck="off"
                      autoFocus={true} disabled={loading}
                      value={defaultName}
                      onChange={(e) => {setDefaultName(e.target.value)}}
                      // onKeyDown={(event) => {
                      //   if(event.key === 'Enter') {
                      //     const value = event.target.value;
                      //     if (value && value !== ''){
                      //       saveSearch(value);
                      //       document.getElementById('saved-search-name').value = '';
                      //     }      
                      //   }
                      // }}
                    />

                    <div className="text-xs uppercase font-semibold opacity-50 mb-1 mt-2">AI match criteria</div>
                    
                    <textarea id="saved-search-ai-match-criteria" type="text"
                      className="bg-gray-800 p-2 h-36 text-sm border-none rounded-md w-full focus:ring-0"
                      placeholder={`Describe the kinds of posts you are looking for, we will use AI to filter them down for you ${currentUser.features.tracked_keyword_ai_criteria ? '(optional)' : '(Requires Pro Plan)'}`}
                      autoComplete="off" spellCheck="off"
                      autoFocus={true} disabled={!currentUser.features.tracked_keyword_ai_criteria || loading}
                      value={aiMatchCriteria}
                      onChange={(e) => {setAiMatchCriteria(e.target.value)}}
                      // onKeyDown={(event) => {
                      //   if(event.key === 'Enter') {
                      //     const value = event.target.value;
                      //     if (value && value !== ''){
                      //       saveSearch(value);
                      //       document.getElementById('saved-search-name').value = '';
                      //     }      
                      //   }
                      // }}
                    />
                    
                    <button className="block flex mt-2 w-full text-center items-center justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none"
                      onClick={() => {
                        saveSearch(document.getElementById('saved-search-name').value);
                        document.getElementById('saved-search-name').value = '';
                      }}
                      disabled={!defaultName}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                      </svg>
                      <span>Track Keyword</span>
                    </button>

                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
};

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    audiences,
  } = state.audiences;

  return {
    currentUser,
    audiences,
  }
};

// this is shared state, so wrap in a dispatch
const mapDispatchToProps = (dispatch) => {
  // const clearSavedSearchUnread = (hash) => {
  //   dispatch(savedSearchOperations.clearSavedSearchUnread(hash))
  // };
  return {
    // clearSavedSearchUnread,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton);

