import React, { useState, useEffect } from "react";

import LoaderFancy from "../common/loaderfancy";
import SelectPopover from "../common/selectPopover";
import CommentTree from "./commenttree";
import { CSVLink } from "react-csv";
// import SubmissionCommentsAnalyze from "./submissioncommentsanalyze";

// import { redditService } from '../../services/reddit';

import { styleKeyword } from '../../utils/text';
import { conversationContainsPhrase, getConversationCsvDict } from '../../utils/reddit';

// import { timeStampToFullTime } from '../../utils/time';

// shows comments for a submission
const SubmissionComments = ({
  submission, 
  keyword, 
  comments, 
  topLevelComments, 
  fetchComments, 
  loading,
  currentUser,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const sortOptions = ['Upvotes', 'Recent', 'Replies'];
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [downloadableResults, setDownloadableResults] = useState([]);


  useEffect(() => {
    if (!comments.length && !loading){
      fetchComments()
    }
  }, [comments, loading]); // get the comments when we load

  useEffect(() => {
    // gets the comments, and puts them into a format to be downloaded.
    setDownloadableResults(getConversationCsvDict(comments || []));
  }, [
    comments
  ]); // Compile CSV when we got results


  const containsKeyword = (reply, keyword) => {
    // checks comment and it's replies recursively to see if it contains the keyword
    if (conversationContainsPhrase(reply, keyword)){
      return true;
    } else if (reply.replies.length){
      var repliesIncludePhrase = false;
      reply.replies.forEach(r => {
        if (repliesIncludePhrase) return; // exit early if one of the replies already has it
        if (containsKeyword(r, keyword)){
          repliesIncludePhrase = true;
        }
      })
      if (repliesIncludePhrase){
        return true
      }
    }
    return false;
  }

  const filterResultsBasedOnKeyword = (replies, keyword) => {
    // recursively filters down replies recursively if they contain the keyword
    const repliesCopy = JSON.parse(JSON.stringify(replies)); // copy to not mess with things between searches
    return repliesCopy
      .filter(r => containsKeyword(r, keyword))
      .map(r => {
        r.replies = filterResultsBasedOnKeyword(r.replies, keyword)
        return r;
      })
  }

  // display utils

  // filter results based on search keyword
  var filteredResults = topLevelComments;
  if (searchTerm){
    // filter down the top-level comments, as well as their replies
    filteredResults = filterResultsBasedOnKeyword(topLevelComments, searchTerm)
  }

  // sort results
  if (sortOption === "Upvotes"){
    filteredResults = filteredResults.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
  } else if (sortOption === "Replies"){
    filteredResults = filteredResults.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
  } else if (!sortOption || sortOption === "Recent"){
    // should be default
    filteredResults = filteredResults.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
  }

  return (
    <div className="py-4 xl:pt-4 xl:pb-0">
      {loading ? (
        <div className="h-96 relative">
          <LoaderFancy />
        </div>
      ) : topLevelComments && topLevelComments.length !== 0 ? (
        <div>
          {/*{currentUser && currentUser.features.analyze && currentUser.features.analyze_submission_comments ? (
            <div className="mb-4">
              <SubmissionCommentsAnalyze
                currentUser={currentUser}
                results={filteredResults} // dynamic based on search
              />
            </div>
          ) : ''}*/}

          <div className="pb-2 flex items-center flex-wrap">
            <div className="text-base opacity-50 flex items-center space-x-1">
              <span>{topLevelComments.length === filteredResults.length ? topLevelComments.length : `${filteredResults.length} / ${topLevelComments.length}`}</span>
              <span className="hidden sm:inline">{' top-level'}</span>
              <span>{topLevelComments.length === 1 ? ' reply' : ' replies'}</span>


              {currentUser.features.download_csv ? (
                <CSVLink
                  data={downloadableResults}
                  filename={`gummysearch-download-comments.csv`}
                  // className="btn btn-primary"
                  target="_blank"
                  // onClick={close}
                  disabled={!downloadableResults.length || !currentUser.features.download_csv}
                  className="ml-2 cursor-pointer text-gray-300 hover:text-gray-100"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                </CSVLink>
              ) : ''}
            </div>
            
            <div className="ml-auto flex items-center">

              <div className="ml-4 flex items-center">
                <div className="text-xs opacity-50">Sort</div>

                <div className="ml-2">
                  <SelectPopover
                    options={sortOptions}
                    currentOption={sortOption}
                    setCurrentOption={setSortOption}
                    labelField={undefined}
                  />
                </div>
              </div>

              <div className="ml-4 flex items-center">
                <div className={`text-xs opacity-50`}>Filter</div>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  className={`ml-2 bg-gray-800 focus:bg-gray-700 border-transparent focus:border-gray-700 placeholder-gray-500 text-white block text-black w-24 text-xs rounded-sm p-0.5 px-1 focus:ring-cyan-500 focus:border-cyan-500`}
                  placeholder={`Keyword`}
                  // disabled={frontendFilters.phrase && frontendFilters.phrase.length}
                  value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <CommentTree comments={filteredResults} keyword={searchTerm} showDivider={true} showRedirect={true} />

          {filteredResults && filteredResults.length > 20 ? (
            <div className="text-center font-semibold opacity-50 hover:opacity-100 cursor-pointer text-sm"
              onClick={() => {
                if (document.getElementById(`submission-drawer`)){
                  // drawer is open, scroll to the very top
                  document.getElementById(`submission-drawer`).scroll(0, 0)
                } else {
                  // drawer not open, so we're in a list view. scroll to this element
                  document.getElementById(`submission-${submission.id}`).scrollIntoView()
                }
              }}
            >
              Scroll to top ↑
            </div>
          ) : ''}
        </div>
      ) : comments && comments.length === 0 ? (
        <div>
          {/*no comments...*/}
        </div>
      ) : ''}
    </div>
  );
}

export default SubmissionComments;

